import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent {
  public showApiDocs = false;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    // Get params if there are any
    this.route.queryParams.subscribe(params => {
      if (params["showApi"] && params["showApi"] === "true") {
        // show enas api doc if param is set
        this.showApiDocs = true;
      }
    });
  }
}

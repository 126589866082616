export const meloCvaData: any = {
  fileName: "Messlokation-Brennwertbezirk.csv",
  annotations: `Eine Messlokation kann den Brennwertbezirk wechseln solange sich Startdatum und Enddatum nicht überschneiden.
        `,
  dataSets: [
    [
      "MeLo-Brennwertbezirk-ID",
      "melo-bwb-0001",
      `Eindeutig (Wenn MeLo-Brennwertbezirk-ID nicht vorhanden ist, wird der Messlokation-Brennwertbezirk-Eintrag über MeLo-ID, Brennwertbezirk-ID und Startdatum identifiziert. Dies führt zu doppelten Objekten, wenn MeLo-ID, Brennwertbezirk-ID oder Startdatum geändert werden. Wir empfehlen deshalb die Mitgabe einer MeLo-Brennwertbezirk-ID.)`,
      "",
      "Nein"
    ],
    [
      "MeLo-ID#HREF#messlokation",
      "melo-0001",
      "Zuordnung zu Messlokation",
      "Ja",
      "Nein"
    ],
    [
      "Brennwertbezirk-ID#HREF#brennwertbezirk",
      "bwb-0001",
      "Zuordnung zu Brennwertbezirk",
      "Ja",
      "Nein"
    ],
    [
      "Startdatum",
      "2017-01-01T00:00:00.000Z",
      "Das Startdatum muss gesetzt sein, sobald ein zweiter Brennwertbezirk der gleichen MeLo zugewiesen wird.",
      "",
      ""
    ],
    ["Loeschen", "0", "0 (nein), 1 (ja)", "", ""]
  ]
};

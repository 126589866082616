import { Injectable } from "@angular/core";
import { data } from "../utils/data/data";

@Injectable({
  providedIn: "root"
})
export class ContentService {
  constructor() {}

  getFileName(csv: string): string {
    const d = data[csv];
    return d["fileName"];
  }

  getAnnotations(csv: string): string {
    const d = data[csv];
    return d["annotations"];
  }

  getExample(csv: string): any {
    const d = data[csv];
    return d["example"];
  }

  getDataSets(csv: string): any[] {
    const d = data[csv];
    return d["dataSets"];
  }

  hasHref(dataField: string): boolean {
    let b = false;
    if (dataField.includes("#")) {
      const chars = dataField.split("#");
      if (chars[1].includes("HREF")) {
        b = true;
      }
    }
    return b;
  }

  getElemWithoutHref(dataField: string): string {
    let str = "";
    const chars = dataField.split("#");
    if (chars[1].includes("HREF")) {
      str = chars[0];
    }
    return str;
  }

  getHref(dataField: string): string {
    let str = "";
    const chars = dataField.split("#");
    if (chars[1].includes("HREF")) {
      str = chars[2];
    }
    return str;
  }
}

import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-list-elem",
  templateUrl: "./list-elem.component.html",
  styleUrls: ["./list-elem.component.css"]
})
export class ListElemComponent implements OnInit {
  @Input() name: string;

  public routingName: string;
  public spacer: boolean;

  constructor() {}

  ngOnInit() {
    this.routingName = this.getRoutingName(this.name);
    this.spacer = !this.routingName.includes("-");
  }

  getRoutingName(name: string): string {
    if (name === "Erläuterung") {
      return "erlaeuterung";
    }
    return name.toLowerCase();
  }
}

export const businessPartnerLocationData: any = {
  fileName: "Geschaeftspartner-Standort.csv",
  annotations: `Ein Geschäftspartner kann den Standort wechseln solange sich Startdatum und Enddatum nicht überschneiden.
    `,
  dataSets: [
    [
      "Geschaeftspartner-Standort-ID",
      "gp-loc-0001",
      `Eindeutig (Wenn Geschaeftspartner-Standort-ID nicht vorhanden ist, wird der Geschaeftspartner-Standort-Eintrag über Geschaeftspartner-ID, Standort-ID und Startdatum identifiziert. Dies führt zu doppelten Objekten, wenn Geschaeftspartner-ID, Standort-ID oder Startdatum geändert werden. Wir empfehlen deshalb die Mitgabe einer Geschaeftspartner-Standort-ID.)`,
      "",
      "Nein"
    ],
    [
      "Geschaeftspartner-ID#HREF#geschaeftspartner",
      "gp-0001",
      "Zuordnung zu Geschäftspartner",
      "Ja",
      "Nein"
    ],
    [
      "Standort-ID#HREF#standort",
      "loc-0001",
      "Zuordnung zu Standort",
      "Ja",
      "Nein"
    ],
    [
      "Startdatum",
      "2017-01-01T00:00:00.000Z",
      "Das Startdatum muss gesetzt sein, sobald ein zweiter Standort dem gleichen Geschäftspartner zugewiesen wird.",
      "",
      ""
    ],
    ["Enddatum", "2018-01-01T00:00:00.000Z", "", "", ""],
    ["Loeschen", "0", "0 (nein), 1 (ja)", "", ""]
  ]
};

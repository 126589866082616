export const meterLocationData: any = {
  fileName: "Zaehler-Standort.csv",
  annotations: `Ein Zähler kann den Standort wechseln solange sich Startdatum und Enddatum nicht überschneiden.
      `,
  dataSets: [
    [
      "Zaehler-Standort-ID",
      "met-loc-0001",
      `Eindeutig (Wenn Zaehler-Standort-ID nicht vorhanden ist, wird der Zaehler-Standort-Eintrag über Zaehler-ID, Standort-ID und Startdatum identifiziert. Dies führt zu doppelten Objekten, wenn Zaehler-ID, Standort-ID oder Startdatum geändert werden. Wir empfehlen deshalb die Mitgabe einer Zaehler-Standort-ID.)`,
      "",
      "Nein"
    ],
    [
      "Zaehler-ID#HREF#zaehler",
      "met-0001",
      "Zuordnung zu Zähler",
      "Ja",
      "Nein"
    ],
    [
      "Standort-ID#HREF#standort",
      "loc-0001",
      "Zuordnung zu Standort",
      "Ja",
      "Nein"
    ],
    [
      "Startdatum",
      "2017-01-01T00:00:00.000Z",
      "Das Startdatum muss gesetzt sein, sobald ein zweiter Standort dem gleichen Zähler zugewiesen wird.",
      "",
      ""
    ],
    ["Enddatum", "2018-01-01T00:00:00.000Z", "", "", ""],
    ["Loeschen", "0", "0 (nein), 1 (ja)", "", ""]
  ]
};

import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-explanations",
  templateUrl: "./explanations.component.html",
  styleUrls: ["./explanations.component.css"]
})
export class ExplanationsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

import { HttpClient } from "@angular/common/http";
import { ContentService } from "./content.service";
import { Component, OnInit } from "@angular/core";
import { CSV_TABLE_COLUMNS } from "../utils/csvTableColumns";
import { Router } from "@angular/router";

@Component({
  selector: "app-content",
  templateUrl: "./content.component.html",
  styleUrls: ["./content.component.css"]
})
export class ContentComponent implements OnInit {
  public csvTableColumns: string[] = CSV_TABLE_COLUMNS;
  private selectedCsv: string;

  public fileName: string;
  public annotations: string;
  public dataSets: any[];

  public errMsg: string;

  constructor(
    private router: Router,
    public contentService: ContentService,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.selectedCsv = this.getNameWithoutRouterSlash(this.router.url);
    this.setFileName(this.selectedCsv);
    this.setAnnotations(this.selectedCsv);
    this.setDataSets(this.selectedCsv);
  }

  getNameWithoutRouterSlash(routerUrl: string): string {
    const chars = routerUrl.split("/");
    return chars[1];
  }

  setFileName(csv: string): void {
    this.fileName = this.contentService.getFileName(csv);
  }

  setAnnotations(csv: string): void {
    this.annotations = this.contentService.getAnnotations(csv);
  }

  setDataSets(csv: string): void {
    this.dataSets = this.contentService.getDataSets(csv);
  }

  onClickDownloadCsv(filename: string): void {
    const csvUrl = `assets/csv-samples/${filename}`;
    this.readCsvData(csvUrl, filename);
  }

  readCsvData(csvUrl: string, csvName: string): void {
    this.http.get(csvUrl, { responseType: "text" }).subscribe(
      data => {
        this.downloadCsv(data, csvName);
      },
      () => {
        this.errMsg = "Die Datei kann nicht geladen werden.";
      }
    );
  }

  downloadCsv(csvData, csvName) {
    var a = document.createElement("a");
    a.setAttribute("style", "display:none;");
    document.body.appendChild(a);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = csvName;
    a.click();
  }
}

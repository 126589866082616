export const tariffPriceData: any = {
  fileName: "Tarif-Preis.csv",
  annotations: `Ein Tarifpreis eines Tarifes gilt immer vom Startdatum bis zum Startdatum des nächsten Tarifpreises.
  `,
  dataSets: [
    [
      "Tarif-Preis-ID",
      "tar-pri-0001",
      "Eindeutig (Wenn Tarif-Preis-ID nicht vorhanden ist, wird der Tarif-Preis-Eintrag über Tarif-ID und Startdatum identifiziert. Dies führt zu doppelten Objekten, wenn Tarif-ID oder Startdatum geändert werden. Wir empfehlen deshalb die Mitgabe einer Tarif-Preis-ID.)",
      "",
      "Nein"
    ],
    ["Tarif-ID#HREF#tarif", "tar-0001", "Zuordnung zu Tarif", "Ja", "Nein"],
    ["Startdatum", "2017-01-01T00:00:00.000Z", "", "Ja", ""],
    [
      "EDV-Nummer",
      "sap_tar_pri_0001",
      "Eindeutig (kann Tarif-Preis-ID entsprechen)",
      "",
      ""
    ],
    ["Grundpreis", "100", "Euro/Jahr (brutto)", "", ""],
    ["Zaehlerentgelt", "20", "Euro/Jahr (brutto)", "", ""],
    ["Abrechnungsentgelt", "0", "Euro/Jahr (brutto)", "", ""],
    ["Netznutzungsentgelt", "10", "ct/kWh (brutto)", "", ""],
    ["Haupttarifpreis", "30", "ct/kWh (brutto) oder ct/m³ (brutto)", "", ""],
    ["Nebentarifpreis", "20", "ct/kWh (brutto)", "", ""],
    ["Mindestpreis", "20", "ct/kWh (brutto)", "", ""],
    ["Maximalpreis", "40", "ct/kWh (brutto)", "", ""],
    ["Steuer", "8", "ct/kWh (brutto)", "", ""],
    ["Marge", "5", "ct/kWh (netto)", "", ""],
    ["EEG", "25", "ct/kWh (brutto)", "", ""],
    ["KWKG", "20", "ct/kWh (brutto)", "", ""],
    ["Nennwaermebelastungs-grenze", "50000", "kW (brutto)", "", ""],
    ["KW-Aufschlag", "20", "Euro/Jahr (brutto)", "", ""],
    ["Loeschen", "0", "0 (nein), 1 (ja)", "", ""]
  ]
};

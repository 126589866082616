export const emissionValueData: any = {
  fileName: "Emission-Emissionswert.csv",
  annotations: `Ein Emissionswert einer Emission gilt immer vom Startdatum bis zum Startdatum des nächsten Emissionswerts.
    `,
  dataSets: [
    [
      "Emission-Emissionswert-ID",
      "emi-we-0001",
      "Eindeutig (Wenn Emission-Emissionswert-ID nicht vorhanden ist wird der Emission-Emissionswert-Eintrag über Emission-ID und Startdatum identifiziert. Dies führt zu doppelten Objekten, wenn Emission-ID oder Startdatum geändert werden. Wir empfehlen deshalb die Mitgabe einer Emission-Emissionswert-ID.)",
      "",
      "Nein"
    ],
    [
      "Emission-ID#HREF#emission",
      "emi-0001",
      "Zuordnung zu Emission",
      "Ja",
      "Nein"
    ],
    ["Wert", "234", "g/kWh, g/m³", "Ja", ""],
    [
      "Erneuerbareenergienanteil",
      "40",
      "Prozent (0 - 100), Nur bei Strom und Gas",
      "",
      ""
    ],
    ["Startdatum", "2017-01-01T00:00:00.000Z", "", "Ja", ""],
    ["Loeschen", "0", "0 (nein), 1 (ja)", "", ""]
  ]
};

export const CSV_NAMES: string[] = [
  "Brennwertbezirk",
  "Brennwertbezirk-Brennwert",
  "Emission",
  "Emission-Emissionswert",
  "Marktlokation",
  "Marktlokation-Messlokation",
  "Marktlokation-Standort",
  "Messlokation",
  "Messlokation-Standort",
  "Messlokation-Zaehler",
  "Messlokation-Brennwertbezirk",
  "Standort",
  "Tarif",
  "Tarif-Preis",
  "Tarif-Preis-Stufe",
  "Vertrag",
  "Vertrag-Abschlag",
  "Vertrag-Marktlokation",
  "Vertrag-Messlokation",
  "Vertrag-Zaehler",
  "Vertrag-Standort",
  "Vertrag-Tarif",
  "Zaehler",
  "Zaehler-Standort",
  "Zaehler-Brennwertbezirk",
  "Geschaeftspartner",
  "Geschaeftspartner-Vertragskonto",
  "Geschaeftspartner-Standort",
  "Vertragskonto",
  "Vertragskonto-Vertrag"
];

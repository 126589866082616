import { Routes } from "@angular/router";
import { ContentComponent } from "./content/content.component";
import { ExplanationsComponent } from "./explanations/explanations.component";

export const APP_ROUTES: Routes = [
  {
    path: "erlaeuterung",
    component: ExplanationsComponent
  },
  {
    path: "brennwertbezirk",
    component: ContentComponent
  },
  {
    path: "brennwertbezirk-brennwert",
    component: ContentComponent
  },
  {
    path: "emission",
    component: ContentComponent
  },
  {
    path: "emission-emissionswert",
    component: ContentComponent
  },
  {
    path: "geschaeftspartner",
    component: ContentComponent
  },
  {
    path: "geschaeftspartner-vertragskonto",
    component: ContentComponent
  },
  {
    path: "geschaeftspartner-standort",
    component: ContentComponent
  },
  {
    path: "marktlokation-messlokation",
    component: ContentComponent
  },
  {
    path: "marktlokation-standort",
    component: ContentComponent
  },
  {
    path: "marktlokation",
    component: ContentComponent
  },
  {
    path: "messlokation",
    component: ContentComponent
  },
  {
    path: "messlokation-brennwertbezirk",
    component: ContentComponent
  },
  {
    path: "messlokation-standort",
    component: ContentComponent
  },
  {
    path: "messlokation-zaehler",
    component: ContentComponent
  },
  {
    path: "standort",
    component: ContentComponent
  },
  {
    path: "tarif-preis-stufe",
    component: ContentComponent
  },
  {
    path: "tarif-preis",
    component: ContentComponent
  },
  {
    path: "tarif",
    component: ContentComponent
  },
  {
    path: "vertrag",
    component: ContentComponent
  },
  {
    path: "vertrag-abschlag",
    component: ContentComponent
  },
  {
    path: "vertrag-standort",
    component: ContentComponent
  },
  {
    path: "vertrag-zaehler",
    component: ContentComponent
  },
  {
    path: "vertrag-marktlokation",
    component: ContentComponent
  },
  {
    path: "vertrag-messlokation",
    component: ContentComponent
  },
  {
    path: "vertrag-tarif",
    component: ContentComponent
  },
  {
    path: "vertragskonto-vertrag",
    component: ContentComponent
  },
  {
    path: "vertragskonto",
    component: ContentComponent
  },
  {
    path: "zaehler",
    component: ContentComponent
  },
  {
    path: "zaehler-brennwertbezirk",
    component: ContentComponent
  },
  {
    path: "zaehler-standort",
    component: ContentComponent
  },
  { path: "**", redirectTo: "erlaeuterung" }
];

export const maloLocationData: any = {
  fileName: "Marktlokation-Standort.csv",
  annotations: `Eine Marktlokation kann den Standort wechseln, solange sich Startdatum und Enddatum nicht überschneiden.
    `,
  dataSets: [
    [
      "MaLo-Standort-ID",
      "malo-loc-0001",
      `Eindeutig (Wenn MaLo-Standort-ID nicht vorhanden ist, wird der Marktlokation-Standort-Eintrag über MaLo-ID, Standort-ID und Startdatum identifiziert. Dies führt zu doppelten Objekten, wenn MaLo-ID, Standort-ID oder Startdatum geändert werden. Wir empfehlen deshalb die Mitgabe einer MaLo-Standort-ID.)`,
      "",
      "Nein"
    ],
    [
      "MaLo-ID#HREF#marktlokation",
      "malo-0001",
      "Zuordnung zu Marktlokation",
      "Ja",
      "Nein"
    ],
    [
      "Standort-ID#HREF#standort",
      "loc-0001",
      "Zuordnung zu Standort",
      "Ja",
      "Nein"
    ],
    [
      "Startdatum",
      "2017-01-01T00:00:00.000Z",
      "Das Startdatum muss gesetzt sein, sobald ein zweiter Standort der gleichen MaLo zugewiesen wird.",
      "",
      ""
    ],
    ["Enddatum", "2018-01-01T00:00:00.000Z", "", "", ""],
    ["Loeschen", "0", "0 (nein), 1 (ja)", "", ""]
  ]
};

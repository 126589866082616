export const contractAdvanceData: any = {
  fileName: "Vertrag-Abschlag.csv",
  annotations: `Die Abschlagszahlung kann sich ändern. Ein Abschlag gilt immer vom Startdatum bis zum Startdatum des nächsten Abschlags.
      `,
  dataSets: [
    [
      "Vertrag-Abschlag-ID",
      "con-ab-0001",
      "Eindeutig (Wenn Vertrag-Abschlag-ID nicht vorhanden ist, wird der Vertrag-Abschlag-Eintrag über Vertrag-ID und Startdatum identifiziert. Dies führt zu doppelten Objekten, wenn Vertrag-ID oder Startdatum geändert werden. Wir empfehlen deshalb die Mitgabe einer Vertrag-Abschlag-ID.)",
      "",
      "Nein"
    ],
    [
      "Vertrag-ID#HREF#vertrag",
      "con-0001",
      "Zuordnung zu Vertrag",
      "Ja",
      "Nein"
    ],
    [
      "Startdatum",
      "2017-01-01T00:00:00.000Z",
      "Das Startdatum muss gesetzt sein, sobald ein zweiter Abschlag dem gleichen Vertrag zugewiesen wird.",
      "Ja",
      ""
    ],
    ["Abschlag", "30", "Euro/Monat (brutto)", "Ja", ""],
    ["Loeschen", "0", "0 (nein), 1 (ja)", "", ""]
  ]
};

export const tariffData: any = {
  fileName: "Tarif.csv",
  annotations: `Die Tariftypen HT/NT, Börse, EEG sind nur bei Medium Strom möglich.
    Der Tariftyp KWKG ist nur bei Medium Gas möglich.
Die Reichweite eines Tarifs gilt global für alle Kunden oder ist spezifisch für einen Kunden (individual).
Die Tariftypen EEG, KWKG sind immer individual.
Bei den Tariftypen Zone, Stufe und Staffel muss mindestens eine Tarif-Preis-Stufe hinterlegt werden.
`,
  dataSets: [
    [
      "Tarif-ID",
      "tar-0001",
      "Eindeutig (kann EDV-Nummer oder Name entsprechen)",
      "Ja",
      "Nein"
    ],
    [
      "EDV-Nummer",
      "sap_tar_0001",
      "Eindeutig (kann Tarif-ID oder Name entsprechen)",
      "",
      ""
    ],
    [
      "Name",
      "Ökostrom",
      "Eindeutig (pro Medium) (kann Tarif-ID oder EDV-Nummer entsprechen)",
      "Ja",
      ""
    ],
    ["Medium", "Strom", "Strom, Gas, Wasser, Wärme", "Ja", "Nein"],
    [
      "Typ",
      "Fix",
      "Fix, HT/NT, Börse, EEG, KWKG, Zone, Stufe, Staffel",
      "Ja",
      "Nein"
    ],
    ["Reichweite", "global", "global, individuell", "Ja", "Nein"],
    ["Startdatum", "2017-01-01T00:00:00.000Z", "", "", ""],
    ["Enddatum", "2018-01-01T00:00:00.000Z", "", "", ""],
    [
      "Standard",
      "0",
      `Standardtarif für Verträge des jeweiligen Mediums
        (0) nein, 1 (ja)
        `,
      "",
      ""
    ],
    ["Mindestlaufzeit", "12", "Angabe in Monaten", "", ""],
    ["Emission-ID#HREF#emission", "emi-0001", "Zuordnung zu Emission", "", ""],
    ["Kuendigungsfrist", "4 Wochen", "Tage, Wochen, Monate, Jahre", "", ""],
    [
      "Vertragsverlaengerungsdauer",
      "12 Monate",
      "Tage, Wochen, Monate, Jahre (nur wenn Vertragsverlängerungsmodus nicht gesetzt ist)",
      "",
      ""
    ],
    [
      "Vertragsverlaengerungsmodus",
      "automatisch",
      "automatisch / manuell",
      "",
      ""
    ],
    ["Beschreibung", "Das ist der Standard Tarif für Strom.", "", "", ""],
    [
      "Gesperrt",
      "0",
      "0 (nein), 1 (ja), wird standardmäßig auf 0 (nein) gesetzt",
      "",
      ""
    ],
    ["Loeschen", "0", "0 (nein), 1 (ja)", "", ""]
  ]
};

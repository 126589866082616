export const contractMeloData: any = {
  fileName: "Vertrag-Messlokation.csv",
  annotations: `Ein Vertrag des Mediums Wasser oder Wärme kann eine oder mehrere Messlokationen enthalten. Die Verrechnung ist nach dem Anlegen nicht mehr veränderbar.
      `,
  dataSets: [
    [
      "Vertrag-MeLo-ID",
      "con-melo-0001",
      `Eindeutig (Wenn Vertrag-MeLo-ID nicht vorhanden ist, wird der Vertrag-Messlokation-Eintrag über Vertrag-ID, MeLo-ID und Startdatum identifiziert. Dies führt zu doppelten Objekten, wenn Vertrag-ID, MeLo-ID oder Startdatum geändert werden. Wir empfehlen deshalb die Mitgabe einer Vertrag-MeLo-ID.)`,
      "",
      "Nein"
    ],
    [
      "Vertrag-ID#HREF#vertrag",
      "con-0001",
      "Zuordnung zu Vertrag",
      "Ja",
      "Nein"
    ],
    [
      "MeLo-ID#HREF#messlokation",
      "melo-0001",
      "Zuordnung zu Messlokation",
      "Ja",
      "Nein"
    ],
    [
      "Verrechnung",
      "plus",
      `plus (Messlokation wird addiert)
      minus (Messlokation wird subtrahiert),
      wird standardmäßig auf plus gesetzt
      `,
      "",
      "Nein"
    ],
    [
      "Startdatum",
      "2017-01-01T00:00:00.000Z",
      "Das Startdatum muss gesetzt sein, sobald ein MeLo dem gleichen Vertrag mehr als einmal zugewiesen wird.",
      "",
      ""
    ],
    ["Enddatum", "2018-01-01T00:00:00.000Z", "", "", ""],
    ["Loeschen", "0", "0 (nein), 1 (ja)", "", ""]
  ]
};

export const meloLocationData: any = {
  fileName: "Messlokation-Standort.csv",
  annotations: `Eine Messlokation kann den Standort wechseln solange sich Startdatum und Enddatum nicht überschneiden.
      `,
  dataSets: [
    [
      "MeLo-Standort-ID",
      "melo-loc-0001",
      `Eindeutig (Wenn MeLo-Standort-ID nicht vorhanden ist, wird der Messlokation-Standort-Eintrag über MeLo-ID, Standort-ID und Startdatum identifiziert. Dies führt zu doppelten Objekten, wenn MeLo-ID, Standort-ID oder Startdatum geändert werden. Wir empfehlen deshalb die Mitgabe einer MeLo-Standort-ID.)`,
      "",
      "Nein"
    ],
    [
      "MeLo-ID#HREF#messlokation",
      "melo-0001",
      "Zuordnung zu Messlokation",
      "Ja",
      "Nein"
    ],
    [
      "Standort-ID#HREF#standort",
      "loc-0001",
      "Zuordnung zu Standort",
      "Ja",
      "Nein"
    ],
    [
      "Startdatum",
      "2017-01-01T00:00:00.000Z",
      "Das Startdatum muss gesetzt sein, sobald ein zweiter Standort der gleichen MeLo zugewiesen wird.",
      "",
      ""
    ],
    ["Enddatum", "2018-01-01T00:00:00.000Z", "", "", ""],
    ["Loeschen", "0", "0 (nein), 1 (ja)", "", ""]
  ]
};

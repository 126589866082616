export const contractAccountData: any = {
  fileName: "Vertragskonto.csv",
  annotations:
    "Ein Vertragskonto muss genau einem Geschäftspartner zugeordnet sein. Die Vertragskontonummer eines Vertragskontos ist nach dem Anlegen nicht mehr veränderbar.",
  dataSets: [
    [
      "Vertragskonto-ID",
      "vk-00001",
      "Eindeutig (kann Vertragskontonummer oder EDV-Nummer entsprechen)",
      "Ja",
      "Nein"
    ],
    ["Vertragskontonummer", "VKONTO391821", "Eindeutig", "Ja", "Nein"],
    [
      "EDV-Nummer",
      "sap_vk_0001",
      "Eindeutig (kann Vertragskontonummer oder Vertragskonto-ID entsprechen)",
      "",
      ""
    ],
    ["Name", "Vertragskonto 1", "", "", ""],
    [
      "Gesperrt",
      "0",
      "0 (nein), 1 (ja), wird standardmäßig auf 0 (nein) gesetzt",
      "",
      ""
    ],
    ["Loeschen", "0", "0 (nein), 1 (ja)", "", ""]
  ]
};

import { Component, OnInit, Input } from "@angular/core";
import { CSV_NAMES } from "../utils/csvNames";

@Component({
  selector: "app-side-nav",
  templateUrl: "./side-nav.component.html",
  styleUrls: ["./side-nav.component.css"]
})
export class SideNavComponent implements OnInit {
  @Input() showApiDocs: boolean;
  // csvNames to display menu items
  public csvNames: string[] = CSV_NAMES;

  constructor() {}

  ngOnInit() {}
}

export const calorificValueData: any = {
  fileName: "Brennwertbezirk-Brennwert.csv",
  annotations:
    "Ein Brennwert eines Brennwertbezirks gilt immer vom Startdatum bis zum Startdatum des nächsten Brennwertes.",
  dataSets: [
    [
      "Brennwertbezirk-Brennwert-ID",
      "bwb-bw-0001",
      "Eindeutig (Wenn Brennwertbezirk-Brennwert-ID nicht vorhanden ist wird der Brennwertbezirk-Brennwert-Eintrag über Brennwertbezirk-ID und Startdatum identifiziert. Dies führt zu doppelten Objekten, wenn Brennwertbezirk-ID oder Startdatum geändert werden. Wir empfehlen deshalb die Mitgabe einer Brennwertbezirk-Brennwert-ID.)",
      "",
      "Nein"
    ],
    [
      "Brennwertbezirk-ID#HREF#brennwertbezirk",
      "bwb-0001",
      "Zuordnung zu Brennwertbezirk",
      "Ja",
      "Nein"
    ],
    ["Brennwert", "11.9", "kWh/m³", "Ja", ""],
    ["Startdatum", "2017-01-01T00:00:00.000Z", "", "Ja", ""],
    ["Loeschen", "0", "0 (nein), 1 (ja)", "", ""]
  ]
};

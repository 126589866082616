export const contractData: any = {
  fileName: "Vertrag.csv",
  annotations:
    "Ein Vertrag muss mindestens einem Vertragskonto zugeordnet sein. Das Medium eines Vertrags ist nach dem Anlegen nicht mehr veränderbar.",
  dataSets: [
    [
      "Vertrag-ID",
      "con-0001",
      "Eindeutig (kann Vertragsnummer oder EDV-Nummer entsprechen)",
      "Ja",
      "Nein"
    ],
    [
      "Vertragsnummer",
      "VER1725818195",
      "Eindeutig (kann Vertrag-ID oder EDV-Nummer entsprechen)",
      "",
      ""
    ],
    [
      "EDV-Nummer",
      "sap_con_0001",
      "Eindeutig (kann Vertrag-ID oder Vertragsnummer entsprechen)",
      "",
      ""
    ],
    ["Medium", "Strom", "Strom, Gas, Wasser, Wärme", "Ja", "Nein"],
    ["Name", "Stromvertrag", "", "", ""],
    ["Startdatum", "2017-01-01T00:00:00.000Z", "", "", ""],
    ["Enddatum", "2018-01-01T00:00:00.000Z", "", "", ""],
    [
      "Gesperrt",
      "0",
      "0 (nein), 1 (ja), wird standardmäßig auf 0 (nein) gesetzt",
      "",
      ""
    ],
    ["Loeschen", "0", "0 (nein), 1 (ja)", "", ""]
  ]
};

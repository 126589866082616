export const meloMeterData: any = {
  fileName: "Messlokation-Zaehler.csv",
  annotations: `Eine Messlokation benötigt mindestens einen Zähler, der ihm zugeordnet ist. Die Messdaten des Zählers können von denen der anderen addiert oder subtrahiert werden.
    `,
  dataSets: [
    [
      "MeLo-Zaehler-ID",
      "melo-met-0001",
      `Eindeutig (Wenn MeLo-Zaehler-ID nicht vorhanden ist, wird der Messlokation-Zaehler-Eintrag über MeLo-ID, Zaehler-ID und Startdatum identifiziert. Dies führt zu doppelten Objekten, wenn MeLo-ID, Zaehler-ID oder Startdatum geändert werden. Wir empfehlen deshalb die Mitgabe einer MeLo-Zaehler-ID.)`,
      "",
      "Nein"
    ],
    [
      "MeLo-ID#HREF#messlokation",
      "melo-0001",
      "Zuordnung zu Messlokation",
      "Ja",
      "Nein"
    ],
    ["Zaehler-ID#HREF#zaehler", "met-0001", "Zuordnung zu Zähler", "Ja", "Nein"],
    [
      "Verrechnung",
      "plus",
      `plus (Zähler wird addiert)
      minus (Zähler wird subtrahiert, wird standardmäßig auf plus gesetzt      
        `,
      "",
      "Nein"
    ],
    [
      "Startdatum",
      "2017-01-01T00:00:00.000Z",
      "Das Startdatum muss gesetzt sein, sobald ein Zähler der gleichen MeLo mehr als einmal zugewiesen wird.",
      "",
      ""
    ],
    ["Enddatum", "2018-01-01T00:00:00.000Z", "", "", ""],
    ["Loeschen", "0", "0 (nein), 1 (ja)", "", ""]
  ]
};

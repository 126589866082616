export const contractMaloData: any = {
  fileName: "Vertrag-Marktlokation.csv",
  annotations: `Ein Vertrag des Mediums Strom oder Gas kann eine oder mehrere Marktlokationen enthalten.
      `,
  dataSets: [
    [
      "Vertrag-MaLo-ID",
      "con-malo-0001",
      `Eindeutig (Wenn Vertrag-MaLo-ID nicht vorhanden ist, wird den Vertrag-Marktlokation-Eintrag über Vertrag-ID, MaLo-ID und Startdatum identifiziert. Dies führt zu doppelten Objekten, wenn Vertrag-ID, MaLo-ID oder Startdatum geändert werden. Wir empfehlen deshalb die Mitgabe einer Vertrag-MaLo-ID.)`,
      "",
      "Nein"
    ],
    [
      "Vertrag-ID#HREF#vertrag",
      "con-0001",
      "Zuordnung zu Vertrag",
      "Ja",
      "Nein"
    ],
    [
      "MaLo-ID#HREF#marktlokation",
      "malo-0001",
      "Zuordnung zu Marktlokation",
      "Ja",
      "Nein"
    ],
    [
      "Startdatum",
      "2017-01-01T00:00:00.000Z",
      "Das Startdatum muss gesetzt sein, sobald eine MaLo dem gleichen Vertrag mehr als einmal zugewiesen wird.",
      "",
      ""
    ],
    ["Enddatum", "2018-01-01T00:00:00.000Z", "", "", ""],
    ["Loeschen", "0", "0 (nein), 1 (ja)", "", ""]
  ]
};

export const businessPartnerContractAccountData: any = {
  fileName: "Geschaeftspartner-Vertragskonto.csv",
  annotations: ``,
  dataSets: [
    [
      "Geschaeftspartner-Vertragskonto-ID",
      "gp-con-0001",
      `Eindeutig (Wenn Geschaeftspartner-Vertragskonto-ID nicht vorhanden ist, wird der Geschaeftspartner-Vertragskonto-Eintrag über Geschaeftspartner-ID und Vertragskonto-ID identifiziert. Dies führt zu doppelten Objekten, wenn die Vertragskonto-ID geändert wird. Wir empfehlen deshalb die Mitgabe einer Geschaeftspartner-Vertragskonto-ID.)`,
      "",
      "Nein"
    ],
    [
      "Geschaeftspartner-ID#HREF#geschaeftspartner",
      "gp-0001",
      "Zuordnung zu Geschäftspartner",
      "Ja",
      "Nein"
    ],
    [
      "Vertragskonto-ID#HREF#vertragskonto",
      "vk-0001",
      "Zuordnung zu Vertragskonto",
      "Ja",
      "Nein"
    ],
    ["Loeschen", "0", "0 (nein), 1 (ja)", "", ""]
  ]
};

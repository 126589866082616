export const contractAccountContractData: any = {
  fileName: "Vertragskonto-Vertrag.csv",
  annotations: `Ein Vertrag kann das Vertragskonto wechseln, solange sich Startdatum und Enddatum nicht überschneiden.
        `,
  dataSets: [
    [
      "Vertragskonto-Vertrag-ID",
      "vk-con-0001",
      `Eindeutig (Wenn Vertragskonto-Vertrag-ID nicht vorhanden ist, wird der Vertragskonto-Vertrag-Eintrag über Vertragskonto-ID, Vertrag-ID und Startdatum identifiziert. Dies führt zu doppelten Objekten, wenn Vertragskonto-ID, Vertrag-ID oder Startdatum geändert werden. Wir empfehlen deshalb die Mitgabe einer Vertragskonto-Vertrag-ID.)`,
      "",
      "Nein"
    ],
    [
      "Vertragskonto-ID#HREF#vertragskonto",
      "vk-0001",
      "Zuordnung zu Vertragskonto",
      "Ja",
      "Nein"
    ],
    ["Vertrag-ID#HREF#vertrag", "con-0001", "Zuordnung zu Vertrag", "Ja", "Nein"],
    [
      "Startdatum",
      "2017-01-01T00:00:00.000Z",
      "Das Startdatum muss gesetzt sein, sobald ein Vertrag dem gleichen Vertragskonto mehr als einmal zugewiesen wird.",
      "",
      ""
    ],
    ["Enddatum", "2018-01-01T00:00:00.000Z", "", "", ""],
    ["Loeschen", "0", "0 (nein), 1 (ja)", "", ""]
  ]
};

export const maloData: any = {
  fileName: "Marktlokation.csv",
  annotations: "",
  dataSets: [
    [
      "MaLo-ID",
      "malo-0001",
      "Eindeutig (kann EDV-Nummer und/oder MaLo-Nummer entsprechen)",
      "Ja",
      "Nein"
    ],
    ["MaLo-Nummer", "12881290109", "Eindeutig", "Ja", "Nein"],
    [
      "EDV-Nummer",
      "sap_malo_0001",
      "Eindeutig (kann MaLo-ID  und/oder MaLo-Nummer entsprechen)",
      "",
      ""
    ],
    ["Medium", "Strom", "Strom, Gas", "Ja", "Nein"],
    ["Typ", "Bezug", "Bezug, Einspeisung", "Ja", "Nein"],
    [
      "Gesperrt",
      "0",
      "0 (nein), 1 (ja), wird standardmäßig auf 0 (nein) gesetzt",
      "",
      ""
    ],
    ["Loeschen", "0", "0 (nein), 1 (ja)", "", ""]
  ]
};

export const tariffPriceStepData: any = {
  fileName: "Tarif-Preis-Stufe.csv",
  annotations: `Eine Preisstufe eines Tarifpreises gilt immer vom Minimalverbrauch bis zum Maximalverbrauch. Falls kein Maximalverbrauch vorhanden ist gilt dieser für jeglichen Verbrauch überhalb des Minimalverbrauchs.
    `,
  dataSets: [
    [
      "Tarif-Preis-Stufe-ID",
      "tar-pri-0001",
      "Eindeutig (Wenn Tarif-Preis-Stufe-ID nicht vorhanden ist, wird der Tarif-Preis-Stufe-Eintrag über Tarif-Preis-ID und Name identifiziert. Dies führt zu doppelten Objekten, wenn Tarif-Preis-ID oder Name geändert werden. Wir empfehlen deshalb die Mitgabe einer Tarif-Preis-Stufe-ID.)",
      "",
      "Nein"
    ],
    [
      "Tarif-Preis-ID#HREF#tarif-preis",
      "tar-pri-0001",
      "Zuordnung zu Tarif-Preis",
      "Ja",
      "Nein"
    ],
    ["Name", "Stufe 1", "Eindeutig (pro Tarif-Preis)", "", ""],
    [
      "EDV-Nummer",
      "sap_tar_pri_stu_0001",
      "Eindeutig (kann Tarif-Preis-Stufe-ID oder Name entsprechen)",
      "",
      ""
    ],
    ["Minimalverbrauch", "20", "kWh oder m³", "Ja", ""],
    ["Maximalverbrauch", "100", "kWh oder m³", "", ""],
    ["Arbeitspreis", "5", "ct/kWh (brutto) oder ct/m³ (brutto)", "Ja", ""],
    ["Loeschen", "0", "0 (nein), 1 (ja)", "", ""]
  ]
};

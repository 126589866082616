export const emissionData: any = {
  fileName: "Emission.csv",
  annotations:
    "Nach dem Erstellen einer Emission ist das Medium nicht mehr veränderbar.",
  dataSets: [
    [
      "Emission-ID",
      "emi-0001",
      "Eindeutig (kann EDV-Nummer oder Name entsprechen)",
      "Ja",
      "Nein"
    ],
    [
      "EDV-Nummer",
      "sap_emi_0001",
      "Eindeutig (kann Emission-ID oder Name entsprechen)",
      "",
      ""
    ],
    [
      "Name",
      "Ökostrom",
      "Eindeutig (pro Medium) (kann EDV-Nummer oder Emission-ID entsprechen)",
      "Ja",
      ""
    ],
    ["Medium", "Strom", "Strom, Gas, Wasser, Wärme", "Ja", "Nein"],
    [
      "Standard",
      "0",
      "Standardemission für Tarife des jeweiligen Mediums. Nur ein Standard pro Medium. (0) nein, 1 (ja).",
      "",
      ""
    ],
    [
      "Gesperrt",
      "0",
      "0 (nein), 1 (ja), wird standardmäßig auf 0 (nein) gesetzt",
      "",
      ""
    ],
    ["Loeschen", "0", "0 (nein), 1 (ja)", "", ""]
  ]
};

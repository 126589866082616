import { tariffData } from "./files/tarif";
import { emissionData } from "./files/emission";
import { emissionValueData } from "./files/emission-emissionswert";
import { cvaData } from "./files/brennwertbezirk";
import { calorificValueData } from "./files/brennwertbezirk-brennwert";
import { maloData } from "./files/marktlokation";
import { meloData } from "./files/messlokation";
import { maloMeloData } from "./files/marktlokation-messlokation";
import { maloLocationData } from "./files/marktlokation-standort";
import { meloLocationData } from "./files/messlokation-standort";
import { meloMeterData } from "./files/messlokation-zaehler";
import { meloCvaData } from "./files/messlokation-brennwerbezirk";
import { locationData } from "./files/standort";
import { tariffPriceData } from "./files/tarif-preis";
import { tariffPriceStepData } from "./files/tarif-preis-stufe";
import { contractData } from "./files/vertrag";
import { contractAdvanceData } from "./files/vertrag-abschlag";
import { contractMaloData } from "./files/vertrag-marktlokation";
import { contractMeloData } from "./files/vertrag-messlokation";
import { contractMeterData } from "./files/vertrag-zaehler";
import { contractLocationData } from "./files/vertrag-standort";
import { contractTariffData } from "./files/vertrag-tarif";
import { meterData } from "./files/zaehler";
import { meterLocationData } from "./files/zaehler-standort";
import { meterCvaData } from "./files/zaehler-brennwerbezirk";
import { businessPartnerData } from "./files/geschaeftspartner";
import { businessPartnerContractAccountData } from "./files/geschaeftspartner-vertragskonto";
import { businessPartnerLocationData } from "./files/geschaeftspartner-standort";
import { contractAccountData } from "./files/vertragskonto";
import { contractAccountContractData } from "./files/vertragskonto-vertrag";

export const data: any = {
  brennwertbezirk: cvaData,
  "brennwertbezirk-brennwert": calorificValueData,
  emission: emissionData,
  "emission-emissionswert": emissionValueData,
  marktlokation: maloData,
  "marktlokation-messlokation": maloMeloData,
  "marktlokation-standort": maloLocationData,
  messlokation: meloData,
  "messlokation-standort": meloLocationData,
  "messlokation-zaehler": meloMeterData,
  "messlokation-brennwertbezirk": meloCvaData,
  standort: locationData,
  tarif: tariffData,
  "tarif-preis": tariffPriceData,
  "tarif-preis-stufe": tariffPriceStepData,
  vertrag: contractData,
  "vertrag-abschlag": contractAdvanceData,
  "vertrag-marktlokation": contractMaloData,
  "vertrag-messlokation": contractMeloData,
  "vertrag-zaehler": contractMeterData,
  "vertrag-standort": contractLocationData,
  "vertrag-tarif": contractTariffData,
  zaehler: meterData,
  "zaehler-standort": meterLocationData,
  "zaehler-brennwertbezirk": meterCvaData,
  geschaeftspartner: businessPartnerData,
  "geschaeftspartner-vertragskonto": businessPartnerContractAccountData,
  "geschaeftspartner-standort": businessPartnerLocationData,
  vertragskonto: contractAccountData,
  "vertragskonto-vertrag": contractAccountContractData
};

export const cvaData: any = {
  fileName: "Brennwertbezirk.csv",
  annotations: "",
  dataSets: [
    [
      "Brennwertbezirk-ID",
      "bwb-0001",
      "Eindeutig (kann EDV-Nummer oder Namen entsprechen)",
      "Ja",
      "Nein"
    ],
    [
      "EDV-Nummer",
      "sap_bwb_0001",
      "Eindeutig (kann Brennwertbezirk-ID oder Namen entsprechen)",
      "",
      ""
    ],
    [
      "Name",
      "Brennwertbezirk A",
      "Eindeutig (kann Brennwertbezirk-ID oder EDV-Nummer entsprechen)",
      "Ja",
      ""
    ],
    [
      "Standard",
      "0",
      "Standard-Brennwertbezirk für Zähler und Messlokationen (des Mediums Gas). Nur ein Brennwertbezirk kann Standard sein. (0) nein, 1 (ja).",
      "",
      ""
    ],
    [
      "Gesperrt",
      "0",
      "0 (nein), 1 (ja), wird standardmäßig auf 0 (nein) gesetzt",
      "",
      ""
    ],
    ["Loeschen", "0", "0 (nein), 1 (ja)", "", ""]
  ]
};

export const contractTariffData: any = {
  fileName: "Vertrag-Tarif.csv",
  annotations: `Ein Vertrag kann den Tarif wechseln. Ein Tarif gilt ab dem Startdatum bis zum Startdatum des nächsten Tarifs.
      `,
  dataSets: [
    [
      "Vertrag-Tarif-ID",
      "con-tar-0001",
      `Eindeutig (Wenn Vertrag-Tarif-ID nicht vorhanden ist, wird der Vertrag-Tarif-Eintrag über Vertrag-ID, Tarif-ID und Startdatum identifiziert. Dies führt zu doppelten Objekten, wenn Vertrag-ID, Tarif-ID oder Startdatum geändert werden. Wir empfehlen deshalb die Mitgabe einer Vertrag-Tarif-ID.)`,
      "",
      "Nein"
    ],
    [
      "Vertrag-ID#HREF#vertrag",
      "con-0001",
      "Zuordnung zu Vertrag",
      "Ja",
      "Nein"
    ],
    ["Tarif-ID#HREF#tarif", "tar-0001", "Zuordnung zu Tarif", "Ja", "Nein"],
    [
      "Startdatum",
      "2017-01-01T00:00:00.000Z",
      "Das Startdatum muss gesetzt sein, sobald ein zweiter Tarif dem gleichen Vertrag zugewiesen wird.",
      "",
      ""
    ],
    ["Enddatum", "2018-01-01T00:00:00.000Z", "", "", ""],
    ["Loeschen", "0", "0 (nein), 1 (ja)", "", ""]
  ]
};

export const maloMeloData: any = {
  fileName: "Marktlokation-Messlokation.csv",
  annotations: `Eine Marktlokation kann mehrere Messlokationen besitzen. Die Messdaten der Messlokation können von den anderen Messlokation addiert oder subtrahiert werden.
  Wenn Verrechnung nicht angegeben wird, wird von plus ausgegangen.
  `,
  dataSets: [
    [
      "MaLo-MeLo-ID",
      "malo-melo-0001",
      `Eindeutig (Wenn MaLo-MeLo-ID nicht vorhanden ist wird der Marktlokation-Messlokation-Eintrag über MaLo-ID, MeLo-ID und Startdatum identifiziert. Dies führt zu doppelten Objekten, wenn MaLo-ID, MeLo-ID oder Startdatum geändert werden. Wir empfehlen deshalb die Mitgabe einer MaLo-MeLo-ID.)`,
      "",
      "Nein"
    ],
    [
      "MaLo-ID#HREF#marktlokation",
      "malo-0001",
      "Zuordnung zu Marktlokation",
      "Ja",
      "Nein"
    ],
    [
      "MeLo-ID#HREF#messlokation",
      "melo-0001",
      "Zuordnung zu Messlokation",
      "Ja",
      "Nein"
    ],
    [
      "Verrechnung",
      "plus",
      `plus (Messlokation wird addiert)
      minus (Messlokation wird subtrahiert),
      wird standardmäßig auf plus gesetzt
      `,
      "",
      "Nein"
    ],
    [
      "Startdatum",
      "2017-01-01T00:00:00.000Z",
      "Das Startdatum muss gesetzt sein, sobald eine MeLo der gleichen MaLo mehr als einmal zugewiesen wird.",
      "",
      ""
    ],
    ["Enddatum", "2018-01-01T00:00:00.000Z", "", "", ""],
    ["Loeschen", "0", "0 (nein), 1 (ja)", "", ""]
  ]
};

export const locationData: any = {
  fileName: "Standort.csv",
  annotations: "",
  dataSets: [
    [
      "Standort-ID",
      "loc-0001",
      "Eindeutig (kann EDV-Nummer entsprechen)",
      "Ja",
      "Nein"
    ],
    [
      "EDV-Nummer",
      "sap_loc_0001",
      "Eindeutig (kann Standort-ID entsprechen)",
      "",
      ""
    ],
    ["Name", "Mein Standort", "", "", ""],
    ["Strasse", "Hauptstraße", "", "Ja", ""],
    ["Hausnummer", "24b", "", "", ""],
    ["Postleitzahl", "70178", "", "Ja", ""],
    ["Stadt", "Stuttgart", "", "Ja", ""],
    [
      "Breitengrad",
      "48.837938°",
      `66° 43′ 12″,
      66° 43′ 12,0″,
      66,7200°
      `,
      "",
      ""
    ],
    [
      "Laengengrad",
      "9.209181°",
      `139° 45′ 59′′ E,
      122,4192° W
      `,
      "",
      ""
    ],
    ["Loeschen", "0", "0 (nein), 1 (ja)", "", ""]
  ]
};

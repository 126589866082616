export const businessPartnerData: any = {
  fileName: "Geschaeftspartner.csv",
  annotations:
    "Bei einer Privatperson muss Vorname, Nachname und Geburtsdatum angegeben werden ohne Firmenname. Bei einer Firma muss der Firmenname angegeben werden, aber kein Vorname, Nachname und Geburtsdatum.",
  dataSets: [
    [
      "Geschaeftspartner-ID",
      "gp-00001",
      "Eindeutig (kann Kundennummer oder EDV-Nummer entsprechen)",
      "Ja",
      "Nein"
    ],
    ["Externessystem", "Netz", "Netz oder Vertrieb", "Ja", ""],
    [
      "Kundennummer",
      "KUN48291",
      "Eindeutig (kann Geschaeftspartner-ID oder EDV-Nummer  entsprechen)",
      "",
      ""
    ],
    [
      "EDV-Nummer",
      "sap_gp_0001",
      "Eindeutig (kann Kundennummer oder Geschaeftspartner-ID entsprechen)",
      "",
      ""
    ],
    [
      "Vorname",
      "Jane",
      "Entweder muss Vorname, Nachname und Geburtsdatum oder Firmenname gesetzt sein",
      "",
      ""
    ],
    [
      "Nachname",
      "Doe",
      "Entweder muss Vorname, Nachname und Geburtsdatum oder Firmenname gesetzt sein",
      "",
      ""
    ],
    [
      "Firmenname",
      "Energy Assistant GmbH",
      "Entweder muss Vorname, Nachname und Geburtsdatum oder Firmenname gesetzt sein",
      "",
      ""
    ],
    [
      "Geburtsdatum",
      "2017-01-01",
      "Entweder muss Vorname, Nachname und Geburtsdatum oder Firmenname gesetzt sein",
      "",
      ""
    ],
    ["Strasse", "Hauptstraße", "", "Ja", ""],
    ["Hausnummer", "24b", "", "", ""],
    ["Postleitzahl", "70178", "", "Ja", ""],
    ["Stadt", "Stuttgart", "", "Ja", ""],
    [
      "Gesperrt",
      "0",
      "0 (nein), 1 (ja), wird standardmäßig auf 0 (nein) gesetzt",
      "",
      ""
    ],
    ["Loeschen", "0", "0 (nein), 1 (ja)", "", ""]
  ]
};

import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { ListElemComponent } from "./list-elem/list-elem.component";
import { RouterModule } from "@angular/router";
import { ContentHeadComponent } from "./content-head/content-head.component";
import { ContentComponent } from "./content/content.component";
import { ContentService } from "./content/content.service";
import { APP_ROUTES } from "./app.routing";
import { ExplanationsComponent } from "./explanations/explanations.component";
import { SideNavComponent } from "./side-nav/side-nav.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatButtonModule } from "@angular/material/button";

@NgModule({
  declarations: [
    AppComponent,
    ListElemComponent,
    ContentHeadComponent,
    ContentComponent,
    ExplanationsComponent,
    SideNavComponent
  ],
  imports: [
    RouterModule.forRoot(APP_ROUTES),
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    MatButtonModule
  ],
  providers: [ContentService],
  bootstrap: [AppComponent]
})
export class AppModule {}

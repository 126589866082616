export const contractMeterData: any = {
  fileName: "Vertrag-Zaehler.csv",
  annotations: `Ein Vertrag kann mehrere Zähler enthalten. Die Messdaten des Zählers können von denen der anderen addiert oder subtrahiert werden.
  Die Verrechnung und der Typ ist nach dem Anlegen nicht mehr veränderbar.  
      `,
  dataSets: [
    [
      "Vertrag-Zaehler-ID",
      "con-met-00001",
      `Eindeutig (Wenn Vertrag-Zaehler-ID nicht vorhanden ist, wird der Vertrag-Zähler-Eintrag über Vertrag-ID, Zaehler-ID und Startdatum identifiziert. Dies führt zu doppelten Objekten, wenn Vertrag-ID, Zaehler-ID oder Startdatum geändert werden. Wir empfehlen deshalb die Mitgabe einer Vertrag-Zaehler-ID.)`,
      "",
      "Nein"
    ],
    [
      "Vertrag-ID#HREF#vertrag",
      "con-0001",
      "Zuordnung zu Vertrag",
      "Ja",
      "Nein"
    ],
    ["Zaehler-ID#HREF#zaehler", "met-0001", "Zuordnung zu Zähler", "Ja", "Nein"],
    [
      "Verrechnung",
      "plus",
      `plus (Messlokation wird addiert)
      minus (Messlokation wird subtrahiert),
      wird standardmäßig auf plus gesetzt
      `,
      "",
      "Nein"
    ],
    ["Typ", "Bezug", `Bezug, Einspeisung`, "Ja", "Nein"],
    [
      "Startdatum",
      "2017-01-01T00:00:00.000Z",
      "Das Startdatum muss gesetzt sein, sobald ein Zähler dem gleichen Vertrag mehr als einmal zugewiesen wird.",
      "",
      ""
    ],
    ["Enddatum", "2018-01-01T00:00:00.000Z", "", "", ""],
    ["Loeschen", "0", "0 (nein), 1 (ja)", "", ""]
  ]
};

export const meterData: any = {
  fileName: "Zaehler.csv",
  annotations:
    "Das Medium und die Zählernummer eines Zählers ist nach dem Anlegen nicht mehr veränderbar.",
  dataSets: [
    [
      "Zaehler-ID",
      "met-0001",
      "Eindeutig (kann Zählernummer entsprechen)",
      "Ja",
      "Nein"
    ],
    ["Zaehlernummer", "STROM008291", "Eindeutig", "Ja", "Nein"],
    [
      "EDV-Nummer",
      "sap_met_0001",
      "Eindeutig (kann Zählernummer entsprechen)",
      "",
      ""
    ],
    ["Medium", "Strom", "Strom, Gas, Wasser, Wärme", "Ja", "Nein"],
    [
      "Gesperrt",
      "0",
      "0 (nein), 1 (ja), wird standardmäßig auf 0 (nein) gesetzt",
      "",
      ""
    ],
    ["Loeschen", "0", "0 (nein), 1 (ja)", "", ""]
  ]
};

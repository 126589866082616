export const meloData: any = {
  fileName: "Messlokation.csv",
  annotations: "",
  dataSets: [
    [
      "MeLo-ID",
      "melo-0001",
      "Eindeutig (kann EDV-Nummer und/oder MeLo-Nummer entsprechen)",
      "Ja",
      "Nein"
    ],
    [
      "MeLo-Nummer",
      "DE 000562 66802 AO6G56M11SN51G21M24S",
      "Eindeutig",
      "Ja",
      "Nein"
    ],
    [
      "EDV-Nummer",
      "sap_melo_0001",
      "Eindeutig (kann MeLo-ID  und/oder MeLo-Nummer entsprechen)",
      "",
      ""
    ],
    ["Medium", "Strom", "Strom, Gas, Wasser, Wärme", "Ja", "Nein"],
    [
      "Gesperrt",
      "0",
      "0 (nein), 1 (ja), wird standardmäßig auf 0 (nein) gesetzt",
      "",
      ""
    ],
    ["Loeschen", "0", "0 (nein), 1 (ja)", "", ""]
  ]
};

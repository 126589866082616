export const meterCvaData: any = {
  fileName: "Zaehler-Brennwertbezirk.csv",
  annotations: `Ein Zähler kann den Brennwertbezirk wechseln solange sich Startdatum und Enddatum nicht überschneiden.
        `,
  dataSets: [
    [
      "Zaehler-Brennwertbezirk-ID",
      "met-bwb-0001",
      `Eindeutig (Wenn Zähler-Brennwertbezirk-ID nicht vorhanden ist, wird der Zaehler-Brennwertbezirk-Eintrag über Zaehler-ID, Brennwertbezirk-ID und Startdatum identifiziert. Dies führt zu doppelten Objekten, wenn Zaehler-ID, Brennwertbezirk-ID oder Startdatum geändert werden. Wir empfehlen deshalb die Mitgabe einer Zaehler-Brennwertbezirk-ID.)`,
      "",
      "Nein"
    ],
    [
      "Zaehler-ID#HREF#zaehler",
      "met-0001",
      "Zuordnung zu Zähler",
      "Ja",
      "Nein"
    ],
    [
      "Brennwertbezirk-ID#HREF#brennwertbezirk",
      "bwb-0001",
      "Zuordnung zu Brennwertbezirk",
      "Ja",
      "Nein"
    ],
    [
      "Startdatum",
      "2017-01-01T00:00:00.000Z",
      "Das Startdatum muss gesetzt sein, sobald ein zweiter Brennwertbezirk dem gleichen Zähler zugewiesen wird.",
      "",
      ""
    ],
    ["Loeschen", "0", "0 (nein), 1 (ja)", "", ""]
  ]
};
